/**
 * @constant TESTIMONIALS
 * @description An array of customer testimonials.
 * @type {Array}
 */
export const TESTIMONIALS = [
  {
    name: "John Smith",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very helpful, and the staff were friendly and knowledgeable. I got a great deal on my new car, and the process was seamless and quick.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
  {
    name: "Charlie Brown",
    title: "Returning Customer",
    testimonial:
      "This is the third car I’ve bought from this dealership, and every experience has been great. The staff is friendly, and their after-sales service is superb.",
    rating: 5,
  },
  {
    name: "Donna Davis",
    title: "Satisfied Customer",
    testimonial:
      "They made the process of trading in my old car for a new one so easy. They gave me a fair price and handled all the paperwork efficiently.",
    rating: 5,
  },
  {
    name: "Edward Johnson",
    title: "Happy Buyer",
    testimonial:
      "The dealership was able to answer all my questions and provide me with all the information I needed. Their level of service and commitment is commendable.",
    rating: 4,
  },
  {
    name: "Fiona White",
    title: "First-time Buyer",
    testimonial:
      "Their selection of cars is excellent, and the staff was very patient and knowledgeable. I never felt rushed or pushed into making a decision.",
    rating: 4,
  },
  {
    name: "George Thompson",
    title: "Long-time Customer",
    testimonial:
      "This dealership always provides top-notch service. Their staff is efficient and friendly, and they have always been able to meet my needs and exceed my expectations.",
    rating: 5,
  },
  {
    name: "Helen Martin",
    title: "Returning Customer",
    testimonial:
      "The finance team at this dealership is incredible. They helped me secure a fantastic financing deal and made sure I understood all the terms and conditions.",
    rating: 5,
  },
]

/**
 * @constant DEALERSHIP_NAME
 * @description The name of the dealership.
 * @type {string}
 */
export const DEALERSHIP_NAME = "CCI Sales"

/**
 * @constant TAGLINE
 * @description The tagline of the dealership.
 * @type {string}
 */
export const TAGLINE = "Car Capital Inc"

/**
 * @constant ADDRESS
 * @description The address of the dealership.
 * @type {string}
 */
export const ADDRESS = "700 W Collins Ave • Orange, CA 92867"

/**
 * @constant PHONE_NUMBER
 * @description The phone number of the dealership.
 * @type {string}
 */
export const PHONE_NUMBER = "714-838-2513"

/**
 * @constant EMAIL
 * @description The email address of the dealership.
 * @type {string}
 */
export const EMAIL = "info@titlelease.com"

/**
 * @constant DISCLAIMER
 * @description The disclaimer showed at the bottom of each inventory page.
 * @type {string}
 */
export const DISCLAIMER = `
The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude additional
fees and taxes. Please contact us for more information on
financing options and terms.
`

/**

/**
 * @constant MAP_URL
 * @description The URL to the Google Maps embed of the dealership's location.
 * @type {string}
 */
export const MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.396653601168!2d-117.86206367424326!3d33.80207558089834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd76613180891%3A0x13d8988e6bd80f6c!2s700%20W%20Collins%20Ave%2C%20Orange%2C%20CA%2092867!5e0!3m2!1sen!2sus!4v1692125575954!5m2!1sen!2sus"

/**
 * @constant MAP_URL
 * @description The URL to the Google Maps embed of the dealership's location.
 * @type {string}
 */
export const MAP_LINK_URL =
  "https://www.google.com/maps/place/700+W+Collins+Ave,+Orange,+CA+92867/@33.8020756,-117.8620637,17z/data=!3m1!4b1!4m6!3m5!1s0x80dcd76613180891:0x13d8988e6bd80f6c!8m2!3d33.8020823!4d-117.8594399!16s%2Fg%2F11c25vwsxx?entry=ttu"

/**
 * @constant HOURS
 * @description The operating hours of the dealership.
 * @type {Array}
 */
export const HOURS = [
  { day: "M-F", hours: "9:30AM - 6:30PM" },
  { day: "Sat", hours: "10AM - 2PM" },
  { day: "Sun", hours: "10AM - 2PM" },
]
