/** @jsxImportSource theme-ui */
import React, { useState } from "react"
import { Box, Flex, Switch, useColorMode, Button } from "theme-ui"
import { Link } from "gatsby"
import {
  navStyles,
  logoStyles,
  buttonStyles,
  ulStyles,
  closeButtonStyles,
  linkStyles,
  switchStyles,
  switchButtonStyles,
  flexStyles,
  navLinkStyles,
  activeLinkStyles, // Catch those waves with the active styles, dude
} from "./headerStyles"
import { DEALERSHIP_NAME } from "../../config/strings"

/**
 * @component Header
 * @description The header of the site, the gateway to the surf spots!
 * @returns {JSX.Element} The header component, get ready to ride!
 */
const Header = () => {
  const [colorMode, setColorMode] = useColorMode()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  /**
   * @function handleToggle
   * @description Flips the color mode switch, like waxing the board!
   * @param {object} e - The event object, like a fresh breeze.
   */
  const handleToggle = e => {
    setColorMode(e.target.checked ? "dark" : "light")
  }

  /**
   * @function toggleMenu
   * @description Like the tides, it opens and closes the mobile menu!
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const menuItems = ["/", "/inventory", "/contact"]
  const labels = ["Home", "Inventory", "Contact"]

  return (
    <Flex as="nav" sx={navStyles}>
      <Button as={Link} to="/" sx={logoStyles}>
        <Box sx={{ display: ["none", "flex"], alignItems: "center" }}>
          {DEALERSHIP_NAME}
        </Box>
        <Box sx={{ display: ["flex", "none"], alignItems: "center" }}>
          {DEALERSHIP_NAME}
        </Box>
      </Button>
      <Button onClick={toggleMenu} sx={buttonStyles}>
        ☰
      </Button>
      {isMenuOpen && (
        <Box as="ul" sx={ulStyles}>
          <Button onClick={toggleMenu} sx={closeButtonStyles}>
            ×
          </Button>
          {menuItems.map((path, index) => (
            <Link to={path} activeClassName="activeLink" key={index}>
              <Button
                as="div"
                sx={{ ...linkStyles, ".activeLink &": activeLinkStyles }}
                onClick={toggleMenu}
              >
                {labels[index]}
              </Button>
            </Link>
          ))}
          <Box sx={switchStyles}>
            <Switch
              checked={colorMode === "dark"}
              onChange={handleToggle}
              sx={switchButtonStyles}
            />
          </Box>
        </Box>
      )}
      <Flex sx={flexStyles}>
        {menuItems.map((path, index) => (
          <Link to={path} activeClassName="activeLink" key={index}>
            <Button
              sx={{ ...navLinkStyles, ".activeLink &": activeLinkStyles }}
            >
              {labels[index]}
            </Button>
          </Link>
        ))}
        <Box sx={switchStyles}>
          <Switch
            checked={colorMode === "dark"}
            onChange={handleToggle}
            sx={switchButtonStyles}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

// No props to ride this wave, but it's good to have the leash ready!
Header.propTypes = {}

export default Header
