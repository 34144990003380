/** @jsxImportSource theme-ui */
import React from "react"
import { Box, Divider, Grid, Heading, Text } from "theme-ui"
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa"
import { Link } from "gatsby"
import {
  footerStyles,
  gridContainerStyles,
  mainBoxStyles,
  headingBoxStyles,
  addressBoxStyles,
  socialLinksBoxStyles,
  socialLinkStyles,
  siteMapBoxStyles,
  siteMapHeadingStyles,
  siteMapDividerStyles,
  siteMapLinkStyles,
  hoursBoxStyles,
  headingStyles,
} from "./footerStyles"
import { ADDRESS, DEALERSHIP_NAME, HOURS } from "../../config/strings"
/**
 * @component SocialLink
 * @description Renders a social media link with an icon.
 * @param {string} href - The URL of the social media link.
 * @param {string} title - The title of the social media platform.
 * @param {ReactNode} children - The content of the social media link.
 * @returns {JSX.Element} SocialLink component
 */
const SocialLink = ({ href, title, children }) => (
  <Box sx={socialLinkStyles}>
    <a
      style={{ color: "inherit" }}
      href={href}
      title={title}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  </Box>
)

/**
 * @component Footer
 * @description The footer component of the website.
 * @returns {JSX.Element} Footer component
 */
const Footer = () => {
  return (
    <Box as="footer" sx={footerStyles}>
      <Grid sx={gridContainerStyles}>
        <Box sx={mainBoxStyles}>
          <Box sx={{ ...headingStyles, headingBoxStyles }}>
            <Heading>{DEALERSHIP_NAME}</Heading>
          </Box>
          <Divider sx={siteMapDividerStyles} />
          <Box sx={addressBoxStyles}>{ADDRESS}</Box>
          <Box sx={socialLinksBoxStyles}>
            <SocialLink
              href="https://facebook.com/dealerclick"
              title="Facebook"
            >
              <FaFacebook size="35" />
            </SocialLink>
            <SocialLink
              href="https://instagram.com/dealerclickdms"
              title="Instagram"
            >
              <FaInstagram size="35" />
            </SocialLink>
            <SocialLink href="https://twitter.com/dealerclick" title="Twitter">
              <FaTwitter size="35" />
            </SocialLink>
          </Box>
        </Box>
        <Box sx={siteMapBoxStyles}>
          <Text as="h3" sx={{ ...headingStyles, siteMapHeadingStyles }}>
            Site Map
          </Text>
          <Divider sx={siteMapDividerStyles} />
          <Box>
            <Link style={siteMapLinkStyles} to="/">
              • Homepage
            </Link>
          </Box>
          <Box>
            <Link style={siteMapLinkStyles} to="/inventory">
              • Inventory
            </Link>
          </Box>
          <Box>
            <Link style={siteMapLinkStyles} to="/contact">
              • Contact
            </Link>
          </Box>
        </Box>
        <Box sx={hoursBoxStyles}>
          <Text sx={headingStyles} as="h3">
            Hours of Operation
          </Text>
          <Divider sx={siteMapDividerStyles} />
          <Text>
            {HOURS.map((item, index) => (
              <Box key={index}>
                <Text sx={{ display: "inline-block", width: "50px" }}>
                  {item.day}
                </Text>
                <Text sx={{ display: "inline-block" }}>{item.hours}</Text>
                <br />
              </Box>
            ))}
          </Text>
        </Box>
      </Grid>
      <br />
    </Box>
  )
}

export default Footer
